import React, {useState, useEffect, useRef} from 'react';
import { CheckBoxCustom, renderStatusCheckBox } from '../../../_components/exam-test/CheckBoxCustom';

const ResultQuestionTrueFalse = (props) => {
    const {dataQuestion, answer, infoQuestion} = props;
    const finalAnswer = JSON.parse(answer);

    const paragraph = infoQuestion?.lesson_paragraph || infoQuestion?.paragraph;

    return (
        <div style={{flex: 1}}>
            {paragraph ? <p style={{
                    paddingBottom: 16
                }}>
                    {paragraph?.trim()}
                </p> : null
            }
            <div style={{display: 'flex', flexDirection: 'column', gap: 32}}>
              {dataQuestion?.map((item, index) => {
                const explainQuestion = item?.list_option[0]?.question_explain?.content_question_text
                                        || item?.list_option[0]?.option_explain || "";
                const userChoiceTrue = finalAnswer[index].user_choice === 'T' || finalAnswer[index].user_choice === true || finalAnswer[index].user_choice === 'True';
                const userChoiceFalse = finalAnswer[index].user_choice === 'F' || finalAnswer[index].user_choice === false || finalAnswer[index].user_choice === 'False';

                return (
                  <div>
                    <p>{index + 1}. {item?.list_option[0]?.question_content}</p>
                    <div style={{paddingLeft: 50}}>
                      <div style={{position: 'relative'}}>
                        {!userChoiceTrue && item?.list_option[0]?.match_option_text?.[0] === 'T' &&
                            item?.list_option[0]?.match_option_text?.[0] === 'T' && (
                          <div style={{position: 'absolute', left: -34, top: 0, width: 24}}>
                              <img
                                style={{ height: 20 }}
                                src="/assets/images/ico_hover_answer.png"
                              />
                          </div>
                        )}
                        <CheckBoxCustom
                            status={renderStatusCheckBox(
                            userChoiceTrue,
                            item?.list_option[0]?.match_option_text?.[0] === 'T',
                            true
                            )}
                            label={'True'}
                        />
                      </div>
                      <div style={{position: 'relative'}}>
                        {!userChoiceFalse && item?.list_option[0]?.match_option_text?.[0] === 'F' &&
                            item?.list_option[0]?.match_option_text?.[0] === 'F' && (
                          <div style={{position: 'absolute', left: -34, top: 0, width: 24}}>
                              <img
                                style={{ height: 20 }}
                                src="/assets/images/ico_hover_answer.png"
                              />
                          </div>
                        )}
                        <CheckBoxCustom
                            status={renderStatusCheckBox(
                            userChoiceFalse,
                            item?.list_option[0]?.match_option_text?.[0] === 'F',
                            true
                            )}
                            label={'False'}
                        />
                      </div>
                    </div>
                    {!!explainQuestion && <div style={{marginTop: 8}}>
                      <p style={{fontWeight: 600}}>Giải thích:</p>
                      <p>{explainQuestion}</p>
                    </div>
                    }
                  </div>
                )
              }) }
            </div>
        </div>
    );
};

export default ResultQuestionTrueFalse;
