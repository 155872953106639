import { configConstants } from "../_constants";
import { apiCaller, apiRequest } from "./../_helpers";

export const teacherService = {
  getDetailHomeWorkExercise,
  getCriteriaWorkExercise,
  getStudentOfClass,
  getStudentOfClassV2,
  getSettingDetail,
  learn,
  getInboxInfo,
  getDetailInbox,
  getDetailContact,
  getRoomChat,
  sendMessage,
  getSystemNotification,
  getExamCardDetail,
  getListClassOnlineAsync,
  getListClassOfflineAsync,
  getDetailClass,
  getListClass,
  getCurriculum,
  getCurriculumFavorite,
  getDetailCurriculumById,
  getDetailHomeWork,
  getHistoryExerciseWithoutClass,
  deleteRollUp,
  getDataAssignSetting,
  getDataAssignProposal,
  postAssignSpecific,
  getListClassOnlineAsyncLimit,
  getListClassOfflineAsyncLimit,
  getDetailCurriculumByIdNew,
  deleteScoreBr,
  getLessonBySkillTeacher,
};

function getDetailHomeWorkExercise(user_exercise_id, library, exercise_type) {
  return apiCaller(
    "/student/api_homework/homework_detail?user_exercise_id=" +
      user_exercise_id +
      "&library=" +
      library +
      "&exercise_type=" +
      exercise_type,
    "GET",
    {},
    null,
    true
  );
}

function getCriteriaWorkExercise(exercise_id) {
  return apiCaller(
    "/api_class/load_exercise_criteria?exercise_id=" + exercise_id,
    "GET",
    {},
    null,
    true
  );
}

function getStudentOfClass(id, limit, offset, isLoading) {
  return apiCaller(
    "/api_class/students?limit=" +
      limit +
      "&offset=" +
      offset +
      "&class_id=" +
      id,
    "GET",
    {},
    null,
    true,
    configConstants.API_URL_SETEST,
    isLoading
  );
}

function getStudentOfClassV2(id, isLoading) {
  return apiCaller(
    "/api_class/students_v2" +
      "?class_id=" +
      id,
    "GET",
    {},
    null,
    true,
    configConstants.API_URL_SETEST,
    isLoading
  );
}

function getSettingDetail(device_id, type) {
  return apiCaller(
    "/api_user/load_setting_app?device_id=" + device_id + "&type=" + type,
    "GET",
    {},
    null,
    true
  );
}

function learn(lesson_id) {
  return apiCaller(
    "/api_lesson/launch_lesson?lesson_id=" + lesson_id,
    "GET",
    {},
    null,
    true
  );
}

function getInboxInfo(classId) {
  let url = "/api_inbox/inbox" + (classId ? "?class_id=" + classId : "");
  return apiCaller(
    url,
    "GET",
    {},
    null,
    true,
    configConstants.API_URL_SETEST,
    false
  );
}

function getDetailInbox(id) {
  return apiCaller("/api_inbox/inbox_detail?id=" + id, "GET", {}, null, true);
}

function getDetailContact(class_id, role) {
  const url = class_id ? "&class_id=" + class_id : "";
  return apiCaller(
    "/api_inbox/list_contact?user_role=" + role + url,
    "GET",
    {},
    null,
    true
  );
}

function getRoomChat(id) {
  return apiCaller(
    "/api_inbox/check_room?to_user_id=" + id,
    "GET",
    {},
    null,
    true
  );
}

function sendMessage(data) {
  return apiCaller("/api_inbox/send", "POST", data, null, false);
}

function getSystemNotification(class_id) {
  const url = class_id ? "?class_id=" + class_id : "";
  return apiCaller("/api_inbox/inbox_system" + url, "GET", {}, null, true);
}

function getExamCardDetail(cartId) {
  return apiCaller(
    "/api_class_offline/exam_card_detail?card_id=" + cartId,
    "GET",
    {},
    null,
    true
  );
}

async function getListClassOnlineAsync() {
  try {
    let data = {
      method: "GET",
    };
    let res = await apiRequest("/api_class/my_classes", data);
    if (res.status === 200) {
      return res.data;
    }
    return [];
  } catch (err) {}
}

async function getListClassOnlineAsyncLimit(limit, offset, screen) {
  let params = `limit=${limit}&offset=${offset}&screen=${screen}`;
  try {
    let data = {
      method: "GET",
    };
    let res = await apiRequest(`/api_teacher/my_classes/?${params}`, data);
    if (res.status === 200) {
      return res.data;
    }
    return [];
  } catch (err) {}
}

async function getListClassOfflineAsync() {
  try {
    let data = {
      method: "GET",
    };
    let res = await apiRequest("/api_class_offline/my_classes", data);
    if (res.status === 200) {
      return res.data;
    }
    return [];
  } catch (err) {}
}
async function getListClassOfflineAsyncLimit(limit, offset) {
  let params = `limit=${limit}&offset=${offset}`;
  try {
    let data = {
      method: "GET",
    };
    let res = await apiRequest(`/api_class_offline/my_classes?${params}`, data);
    if (res.status === 200) {
      return res.data;
    }
    return [];
  } catch (err) {}
}

async function getDetailClass(id) {
  try {
    let data = {
      method: "GET",
    };
    let res = await apiRequest("/api_class/class?id=" + id, data);
    if (res.status === 200) {
      return res.data;
    }
    return [];
  } catch (err) {}
}

function getListClass() {
  return apiCaller("/api_class/my_classes", "GET", {}, null, true);
}

function getCurriculum(type) {
  return apiCaller(
    "/api_curriculum/courses_name?type=" + type,
    "GET",
    {},
    null,
    true
  );
}

function getCurriculumFavorite(user_id) {
  return apiCaller(
    "/api_wish_list/my_wish_list?user_id=" + user_id,
    "GET",
    {},
    null,
    true
  );
}

function getDetailCurriculumById(id) {
  return apiCaller("/api_curriculum/course?id=" + id, "GET", {}, null, true);
}

function getDetailCurriculumByIdNew(id) {
  return apiCaller(
    "/api_curriculum/course_bycurriculumid?id=" + id,
    "GET",
    {},
    null,
    true
  );
}

function getLessonBySkillTeacher(id) {
  return apiCaller(
    "/api_curriculum/lessons_by_skill?unit_id=" + id,
    "GET",
    {},
    null,
    true
  );
}

function getDetailHomeWork(class_id, exercise_id, library, type) {
  return apiCaller(
    "/api_class/home_work_detail?class_id=" +
      class_id +
      "&exercise_id=" +
      exercise_id +
      "&library=" +
      library,
    "GET",
    {},
    null,
    true
  );
}

function getHistoryExerciseWithoutClass(
  param = { skill: [] },
  filter_time = false,
  offset = 0,
  pageSize = 30
) {
  let uri = `/api_class/class_homework_v2?type=all&limit=${pageSize}&offset=${offset}`;
  param.skill.forEach((skill) => {
    uri += "&skill[]=" + skill.toLocaleLowerCase();
  });
  if (filter_time) {
    uri += "&from_date=" + param.start_time + "&to_date=" + param.end_time;
  }
  return apiCaller(uri, "GET", {}, null, true);
}

// Update Class Offline

// Roll Up
async function deleteRollUp(id) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("id", id);
  try {
    const res = await apiCaller(
      `/api_class_offline/roll_up`,
      "DELETE",
      urlencoded,
      null,
      true
    );
    return res;
  } catch (e) {}
}

// Roll Up
async function deleteScoreBr(id) {
  var body = {
    card_id: id,
  };
  try {
    const res = await apiCaller(
      `/api_class_offline/class_exam_card`,
      "PUT",
      body,
      null,
      true
    );
    return res;
  } catch (e) {}
}

// Assign Specific
async function getDataAssignSetting(class_id, student_list) {
  try {
    const res = await apiCaller(
      "/api_assignment/setting_default?id_class=" +
        class_id +
        "&student_list=" +
        student_list,
      "GET",
      {},
      null,
      true
    );
    return res;
  } catch (e) {
    return e?.toString();
  }
}

async function getDataAssignProposal(type, body) {
  try {
    const res = await apiCaller(
      "/api_assignment/" + type,
      "POST",
      body,
      null,
      true
    );
    return res;
  } catch (e) {}
}

async function postAssignSpecific(data) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("students", JSON.stringify(data.students));
  urlencoded.append("exercise_per_hs", JSON.stringify(data.exercise_per_hs));
  urlencoded.append("before_start_time", data.before_start_time);
  urlencoded.append("end_time", data?.end_time);
  urlencoded.append("start_time", data?.start_time);
  urlencoded.append("class_id", data.class_id);
  urlencoded.append("note", data.note);
  urlencoded.append("remind_before", data.remind_before);
  urlencoded.append("assign_type", data.assign_type);
  urlencoded.append("unit_num", data.unit_num);

  try {
    const res = await apiCaller(
      "/api_assignment/assign_homework",
      "POST",
      urlencoded,
      null,
      true
    );
    return res;
  } catch (e) {}
}
