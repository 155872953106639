import React from "react";
import { isValidUrl } from "../../../_helpers/utils";

export default function ResultQuestionProject({dataQuestion}) {

  return (
    <div 
      style={{
        flex: 1, 
        marginTop: 16,
        borderWidth: 1,
        borderColor: '#cdcdcd',
        borderRadius: 8,
        backgroundColor: '#ffffff',
        padding: 16,
      }}
    >
      <p style={{fontWeight: 700, color: '#000'}}>{dataQuestion?.question}</p>
      <div style={{paddingTop: 16}}>
        {dataQuestion?.instruction?.map((item, index) => {
          return (
            <div style={{paddingTop: 16}} key={index}>
              <p style={{fontSize: 16, fontWeight: 'bold', color: '#000'}}>{`Gợi ý ${index + 1}:`}</p>
              <div>
                {item?.i_text?.split('\n')?.map((line, iLine) => {
                  return (
                    <p style={{fontSize: 16, display: 'flex', gap: 3}} key={iLine}>
                      {line?.split(' ')?.map((text, iText) => {
                        if (isValidUrl(text)) {
                          return <a style={{color: 'blue'}} key={iText} href={text} target="_blank">{text}</a>
                        }
                        return (
                          <p key={iText}>{text}</p>
                        )
                      })}
                    </p>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}