import { useState } from 'react';
import RootCheckBox from '../RootCheckBox';
import './style.css';
import moment from 'moment';
import { apiCaller, history } from '../../_helpers';
import InputDate from '../Auth/InputDate';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { alertActions } from '../../_actions';
import { teacherConstants } from '../../_constants';

export default function PopupGiveTurnHomework({ open = false, onClose, listData, classId, listStudent, onComplete, screen}) {
  if(!open) {
    return null;
  }
  const [startDate, setStartDate] = useState()
  const [startDateError, setStartDateError] = useState('')
  const [endDate, setEndDate] = useState()
  const [endDateError, setEndDateError] = useState('')
  const [listIdSelected, setListIdSelected] = useState([])
  const dispatch = useDispatch()

  const handleSelectAll = () => {
    if(listIdSelected.length === listData?.length) {
      setListIdSelected([])
      return;
    }
    const listId = listData?.map(item => item?.turn_id)
    setListIdSelected(listId)
  }

  const handleSelectTurn = (item) => {
    if(listIdSelected.includes(item?.turn_id)) {
      setListIdSelected(prev => prev.filter(id => id !== item?.turn_id))
      return;
    }
    setListIdSelected(prev => [...prev, item?.turn_id])
  }

  const handleSubmit = async () => {
    if(!validateParams()) {
      return;
    }
    const data = {
      class_id: classId,
      student_list: JSON.stringify(listStudent),
      turn_assign_list: JSON.stringify(listIdSelected),
      start_date: startDate ? dayjs(startDate).format('DD-MM-YYYY') : undefined,
      end_date: endDate ? dayjs(endDate).format('DD-MM-YYYY') : undefined
    }
    try {
      const res = await apiCaller('/teacher/api_exercise_assign/giving_homework_turn', 'post', data)
      if(res?.status) {
        dispatch(
          alertActions.success({
            message: res?.msg,
            screen: screen ? screen : teacherConstants.SCREEN_ADD_STUDENT,
            onCompletePopup: onComplete
          })
        );
      }
    } catch (error) {
      dispatch(
        alertActions.error({
          message: error,
          isShowPopup: true,
          screen: screen ? screen : teacherConstants.SCREEN_ADD_STUDENT,
        })
      );
    }
  }

  const changeStartDate = (date) => {
    let errStartDate = '';
    let errEndDate = '';
    const newDate = dayjs(date).set('hour', 0).set('minute', 0).set('second', 0);
    const yesterday = dayjs(new Date().setDate(new Date().getDate() - 1)).set('hour', 23).set('minute', 59).set('second', 59);
    if(newDate.isBefore(yesterday)) {
      errStartDate = 'Ngày bắt đầu không được ở quá khứ'
    };
    if (!!endDate && dayjs(endDate).isBefore(newDate)) {
      errEndDate = "Ngày kết thúc không được nhỏ hơn ngày bắt đầu";
    };
    setStartDateError(errStartDate);
    setEndDateError(errEndDate);
    setStartDate(date);
  };

  const changeEndDate = (date) => {
    let err = '';
    const newDate = dayjs(date).set('hour', 23).set('minute', 59).set('second', 59);
    const yesterday = dayjs(new Date().setDate(new Date().getDate() - 1)).set('hour', 23).set('minute', 59).set('second', 59);
    if(newDate.isBefore(yesterday)) {
      err = 'Ngày kết thúc không được ở quá khứ';
    };
    if (!!startDate && newDate.isBefore(dayjs(startDate))) {
      err = "Ngày kết thúc không được nhỏ hơn ngày bắt đầu";
    };
    setEndDateError(err);
    setEndDate(date);
  };

  const validateParams = () => {
    if((!!startDate && !endDate) || (!startDate && !!endDate)) {
      return false;
    }
    return !!listIdSelected.length && !startDateError && !endDateError;
  }

  const renderIconDate = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path 
          fill="#404041" 
          d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z"
        />
      </svg>
    )
  }

  return (
    <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open give-turn-homework-modal" uk-modal="">
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" 
        style={{ 
          width: '50%', 
          height: '80vh',
          paddingLeft: 0, 
          paddingRight: 0, 
          display: 'flex', 
          flexDirection: 'column' 
        }}
      >
        <div className='give-turn-homework-header'>
          <span>Giao bài</span>
        </div>
        <div className='give-turn-homework-body scrollbar-custom'>
          <p className='give-turn-title'>Nếu bạn muốn giao các bài tập đã từng giao cho học sinh mới, vui lòng chọn bài muốn giao</p>
          <div className='give-turn-check-all'>
            <RootCheckBox checked={listIdSelected.length === listData?.length} label={'Chọn tất cả'} onClick={handleSelectAll}/>
          </div>
          <div className="origin-vertical give-turn-list">
            {listData.map(item => (
              <div key={item?.turn_id} className="p-v-10 origin-vertical gap-8 give-turn-item">
                <div className="flex align-items-center flex-m">
                  <div className="manage-exercise-item-range-date">
                    <span>{moment(item?.assign_date).format('DD/MM/YYYY')}</span>
                  </div>
                  <RootCheckBox checked={listIdSelected.includes(item?.turn_id)} label={''} onClick={() => handleSelectTurn(item)}/>
                </div>
                <div className="flex flex-m">
                  <span style={{flex: 1}}>
                    <span 
                      style={{
                        color: item?.type == 1 ? '#EB5757' : item?.type == 2 ? '#FF7A00' : '#84C241', 
                        fontWeight: 700,
                      }}
                    >
                      {item?.type == 1 ? 'Giao bài tự do' : item?.type == 2 ? 'Giao bài theo yêu cầu' : 'Giao bài theo năng lực'}
                    </span>
                    {' '}
                    {item?.unit !== null && Number(item?.unit) >= 0 && <span>{'- Unit ' + item?.unit}</span>}
                  </span>
                  <div className="d-flex gap-4 w-per-25" style={{flex: 1}}>
                    <img src="/assets/images/icon/icon_clarity_book-line.png" width={20}  />
                    <span>{`${item?.total_homework} bài tập`}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='give-turn-homework-footer'>
          <div className='give-turn-date-range'>
            <InputDate
              styleContainer={{flex: 1}}
              className="give-turn-date"
              value={startDate}
              setValue={changeStartDate}
              name="startDate"
              renderLabelIcon={renderIconDate}
              errorText={startDateError}
              errorAbsolute={true}
              placeholder={"Ngày bắt đầu"}
              popperPlacement='top'
              typeErrText='underAbsolute'
            />
            <InputDate
              styleContainer={{flex: 1}}
              className="give-turn-date"
              value={endDate}
              setValue={changeEndDate}
              name="endDate"
              renderLabelIcon={renderIconDate}
              errorText={endDateError}
              errorAbsolute={true}
              placeholder={"Ngày kết thúc"}
              popperPlacement='top'
              typeErrText='underAbsolute'
            />
          </div>
          <div className='give-turn-action'>
            <button className="btn-default mr-10" onClick={onClose}>Huỷ</button>
            <button 
              disabled={!validateParams()} 
              className={"btn-bg-blue btn-default " + (!validateParams() ? 'btn-disable' : '')} 
              onClick={handleSubmit}  
            >
              Giao bài
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}