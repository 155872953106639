import { useSelector } from "react-redux";
import { Header } from "../../../../_components/Admin/Header";
import { apiCaller, history } from "../../../../_helpers";
import { Link, useParams } from "react-router-dom";
import './DetailAssignedHomeWork.style.scss'
import { useState } from "react";
import { useEffect } from "react";
import $ from 'jquery'
import { configConstants } from "../../../../_constants";
import moment from 'moment'

export default function DetailAssignedHomeWork() {
  const authentication = useSelector((state) => state.authentication);
  const { id, turn_id } = useParams();
  const startTime = new URLSearchParams(window.location.search).get("startTime");
  const endTime = new URLSearchParams(window.location.search).get("endTime");
  const indexTab = new URLSearchParams(window.location.search).get("indexTab");
  const [indexTabView, setIndexTabView] = useState(indexTab === null || indexTab === undefined ? 0 : Number(indexTab));
  const [isLoadingByStudent, setIsLoadingByStudent] = useState(false);
  const [listDataByStudent, setListDataByStudent] = useState([]);
  const [isLoadingByExercise, setIsLoadingByExercise] = useState(false);
  const [listDataByExercise, setListDataByExercise] = useState([])
  const [baseUrl, setBaseUrl] = useState(configConstants.BASE_URL)

  const getDataByStudent = async () => {
    setIsLoadingByStudent(true);
    const url = '/api_exercise_report/turn_user_report?turn_id=' + turn_id
    try {
      const res = await apiCaller(url, 'get')
      if(res?.status) {
        setListDataByStudent(res?.data)
      }
      setIsLoadingByStudent(false);
    } catch (err) {
      setIsLoadingByStudent(false);
    }
  }

  const getDataByExercise = async () => {
    setIsLoadingByExercise(true)
    const url = '/api_exercise_report/turn_lesson_report?turn_id=' + turn_id
    try {
      const res = await apiCaller(url, 'get')
      if(res?.status) {
        setListDataByExercise(res?.data)
      }
      if(res?.base_url) {
        setBaseUrl(res?.base_url)
      }
      setIsLoadingByExercise(false)
    } catch (error) { 
      setIsLoadingByExercise(false)
    }
  }

  const exportFile = async () => {
    const url = '/teacher/api_exercise_assign/get_report_turn_link?turn_id=' + turn_id
    try {
      const res = await apiCaller(url, 'get')
      if(res?.status) {
        window.open(res?.base_url + res?.file_excel, '_blank');
      }
    } catch (err) {
      console.log(err)
    }
  }

  const bgColorGroup = (index) => {
    switch (index) {
      case 0:
        return '#EB5757'
      case 1:
        return '#FF7A00'
      case 2:
        return '#01AEF0'
      case 3:
        return '#01CC7D'
      default:
        return '#EB5757'
    }
  }

  useEffect(() => {
    if(isLoadingByStudent || isLoadingByExercise) {
      $(".loading").removeClass("hide");
      return
    }
    $(".loading").addClass("hide");
  }, [isLoadingByStudent, isLoadingByExercise])

  useEffect(() => {
    getDataByStudent();
    getDataByExercise();
  }, [])

  return (
    <div className="sunE-right-container rel detail-assigned-home-work-page">
      <Header
        title={(startTime || '') + (endTime ? ` - ${endTime}` : '')}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/class/view/" + id + '/exercise')
        }
        component={
          <button className="btn-line-blue" onClick={exportFile} style={{marginRight: 16}}>
            Xuất file
          </button>
        }
      />
      <div className="sunE-content sunE-class-content">
        <div className="detail-assigned-home-work-content">
          <div className="detail-assigned-tab-view-container">
            <div className="detail-assigned-tab-view-content">
              <div className={'detail-assigned-tab-view-item ' + (indexTabView === 0 ? 'active' : '')} onClick={() => setIndexTabView(0)}>
                <span style={{alignSelf: 'flex-start'}}>Xem theo học sinh</span>
              </div>
              <div className={'detail-assigned-tab-view-item ' + (indexTabView === 1 ? 'active' : '')} onClick={() => setIndexTabView(1)}>
                <span style={{alignSelf: 'flex-end'}}>Xem theo bài tập</span>
              </div>
            </div>
          </div>
          {indexTabView === 0 && <div className="detail-assigned-list-student-container scrollbar-custom">
            <div className="detail-assigned-list-student-content">
              {listDataByStudent?.map(item => {
                const isGiveLater = (item?.end_time && endTime && !moment(item?.end_time).isSame(moment(endTime, 'DD/MM/YYYY').set({hour:23,minute:59,second:59}))) ||
                                  (item?.start_time && startTime && !moment(item?.start_time).isSame(moment(startTime, 'DD/MM/YYYY').set({hour:0,minute:0,second:0})));

                return (
                  <Link 
                    to={ 
                      "/" +
                      authentication.role +
                      "/class/view/" + id +
                      "/exercise/detail-assigned/" + turn_id +
                      '/detail-student/' + item?.student_id +
                      '?heading=' + item?.name +
                      '&startTime=' + startTime + '&endTime=' + endTime
                    } 
                    key={item?.student_id}
                  >
                    <div className="detail-assigned-item-student">
                      <div className="detail-assigned-avatar-student">
                        <img src={baseUrl + item?.avatar} />
                      </div>
                      <div className="detail-assigned-info-student">
                        <p style={{fontSize: '18px', fontWeight: 'bold'}}>
                          {item?.name}
                          {isGiveLater && <span style={{fontWeight: 400}}>{' ( giao bài sau )'}</span>}
                        </p>
                        <div className="flex flex-m">
                          <div className="d-flex gap-4 align-item-center w-per-33">
                            <img src="/assets/images/icon/icon_charm_circle-tick.png"/>
                            <span>{'Đã làm ' + item?.total_lesson_done + '/' + item?.total_assign}</span>
                          </div>
                          <div className="d-flex gap-4 align-item-center w-per-33">
                            <img src="/assets/images/icon/icon_ep_warning-filled.png"/>
                            <span>{item?.total_overdue + ' bài quá hạn'}</span>
                          </div>
                          <div className="d-flex gap-4 align-item-center w-per-33">
                            <span style={{color: '#00BEB4', fontWeight: 'bold'}}>{'Điểm trung bình: ' + (item?.average_score >= 0 ? Math.round(Number(item?.average_score) * 100) / 100 : '__')}</span>
                          </div>
                        </div>
                        {isGiveLater && <span>{`Thời hạn nộp bài: ${moment(item?.start_time).format('DD/MM/YYYY')} - ${moment(item?.end_time).format('DD/MM/YYYY')}`}</span>}
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>}
          {indexTabView === 1 && <div className="detail-assigned-list-exercise-container scrollbar-custom">
            <div className="detail-assigned-list-exercise-content">
              {listDataByExercise?.map(item => {
                const score_group = item?.score_group?.map(group => ({
                  ...group,
                  percent: Math.round(group?.percent * 100) / 100
                }));
                // const totalPercent = score_group?.reduce((sum, group) => sum + group.percent, 0);
                // const difference = (100 - totalPercent).toFixed(2);
                // if(difference !== 0) {
                //   const firstExerciseHasPercent = score_group?.find(group => !!group?.percent)
                //   if(firstExerciseHasPercent) {
                //     firstExerciseHasPercent['percent'] = firstExerciseHasPercent?.percent + Number(difference)
                //   }
                // }
                return (
                  <Link 
                    to={ 
                      "/" +
                      authentication.role +
                      "/class/view/" + id +
                      "/exercise/detail-assigned/" + turn_id +
                      '/detail-lesson/' + item?.lesson_id +
                      '/' + item?.unit_id +
                      '?heading=' + item?.lesson_name +
                      '&startTime=' + startTime + '&endTime=' + endTime
                    } 
                    key={item?.lesson_id}
                  >
                    <div className="detail-assigned-item-exercise">
                      <div className="detail-assigned-exercise-section left-side">
                        <div className="detail-assigned-avatar-exercise">
                          <img 
                            src={
                              "/assets/images/teacher/giaobai/" +
                              item.skill?.toLocaleLowerCase() +
                              ".png"
                            } 
                          />
                        </div>
                        <div className="detail-assigned-info-exercise">
                          <div className="detail-assigned-exercise-name">
                            {item?.level&& <span 
                              style={{
                                backgroundColor: item?.level === 'easy' ? '#84C241' : item?.level === 'normal' ? '#F8AF36' : '#EB5757' , 
                                textTransform: 'capitalize'
                              }}
                            >
                              {item?.level === 'normal' ? 'Medium' : item?.level}
                            </span>}
                            {item?.lesson_name}
                          </div>
                          {(item?.unit_number || item?.unit_name) && 
                            <p>
                              {(item?.unit_number ? ('Unit ' + item?.unit_number) : '') + 
                              (item?.unit_number && item?.unit_name ? ' - ' : '') +
                              (item?.unit_name ? item.unit_name : '')
                              }
                            </p>
                          }
                          <p>{item?.curriculum_name}</p>
                          <p>
                            Học sinh đã làm:
                            {' '}
                            <span style={{color: '#00BEB4', fontWeight: 'bold'}}>{item?.total_student_done + '/' + item?.total_student_assign}</span>
                          </p>
                        </div>
                      </div>
                      <div className="detail-assigned-exercise-section right-side">
                        <div className="detail-assigned-score-exercise">
                          <p>
                            Điểm trung bình:
                            {' '}
                            <span style={{color: '#00BEB4', fontWeight: 'bold'}}>{item?.average_score >= 0 ? Math.round(item?.average_score * 100) / 100 : '__'}</span>  
                          </p>
                          <div className="detail-assigned-score-statistic">
                            {score_group.map((group, iGroup) => (
                              <div key={iGroup} className="detail-assigned-score-statistic-item" style={{marginBottom: '3px'}}>
                                <div style={{
                                  width: '14px',
                                  height: '14px',
                                  backgroundColor: bgColorGroup(iGroup),
                                }}/>
                                <span style={{fontSize: 16}}>
                                  {group?.name + ' điểm'}
                                  {' '}
                                  <span className="font-weight-bold" style={{fontSize: 16}}>{`(${group?.percent}%)`}</span>
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}