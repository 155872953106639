export const convertSkillVN = (skill) => {
  switch (skill) {
          case 'vocabulary':
              return "Học từ vựng";
          case 'listening':
              return "Luyện nghe";
          case 'grammar':
              return "Học ngữ pháp";
          case 'speaking':
              return "Luyện nói";
          case 'reading':
              return "Luyện đọc";
          case 'writing':
              return "Luyện viết";
          case 'pronunciation':
              return "Học phát âm";
          case 'mini_test':
              return "Kiểm tra";
          case 'exam':
              return "Kiểm tra";
          case 'project':
              return "Bài tập lớn";          
      default:
          return ""
  }
}

export const convertSingleQuote = (str) => {
    if(typeof str !== 'string') return str;
    return str.replaceAll("‘", "'").replaceAll("’", "'").replaceAll("‛", "'");
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

export const toListDownLine = (string) => {
    var str
    try {
      str = string.replace('\t', ' ');
    } catch (error) {
      str = string
    }
    var strList = str.split('\n')
    return strList
}

export const validWord = (s) => {
    if (!s || s.length == 0) {
      return s;
    }
    let res = s
      .toLowerCase()
      .replace(/[‘\u2018\u2019]/g, "'")
      .replace(/\s+/g, ' ')
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]+$/g, "")
      .trim();
    while (res.indexOf('  ') > -1) {
      res = res.replace(/ {2}/g, ' ');
    }
    while (
      res.charAt(res.length - 1) === '.' ||
      res.charAt(res.length - 1) === ','
    ) {
      res = res.substring(0, res.length - 1).trim();
    }
    return res.trim();
  };

  export const capitalize = (str) => {
    if(!str) return ''
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  export const isValidUrl = urlString=> {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
  }

  export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }