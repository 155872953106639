import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { userConstants } from "../../../_constants";
import { alertActions, teacherActions } from "../../../_actions";
import { Alert } from "../../../_components/Alert";
import { AlertError } from "../../../_components/Alert/error";
import { Header } from "../../../_components/Admin/Header";
import { apiRequestLogout } from "../../../_helpers";
import API from "../../../_apis/APIConstants";
import { persistor } from "../../..";

function ChangePasswordPage() {
    const alert = useSelector((state) => state.alert);
    const authentication = useSelector((state) => state.authentication);
    const [confirmPassWord, setConfirmPassword] = useState(false);
    const [sttMinPassWord, setSttMinPassword] = useState(false);
    const [sttMinOldPassWord, setSttMinOldPassword] = useState(false);
    const [sttMinConfirmPassWord, setSttMinConfirmPassword] = useState(false);


    const [checkConfirmPassWord, setCheckConfirmPassword] = useState(false);
    const [checkMinPassWord, setCheckMinPassword] = useState(false);

    const isNeedChangePassword = window.location.pathname === '/student/need-change-password' && authentication.need_change_password == 1

    const [inputs, setInputs] = useState({
        password: "",
        re_password: "",
        old_password: "",
    });
    const dispatch = useDispatch();
    const history = useHistory();

    const [sttOldPassword, setSttOldPassword] = useState({
        type: "password",
        status: "show",
    });
    const [sttPassword, setSttPassword] = useState({
        type: "password",
        status: "show",
    });
    const [sttRePassword, setSttRePassword] = useState({
        type: "password",
        status: "show",
    });

    const { password, re_password, old_password } = inputs;

    const setDefaultError = () => {
        setConfirmPassword(false);
        setSttMinPassword(false);
        setSttMinOldPassword(false);
        setSttMinConfirmPassword(false);
        setCheckConfirmPassword(true);
        setCheckMinPassword(true);
        dispatch(
            alertActions.error({
                message: "",
                screen: userConstants.SCREEN_RESET_PASSWORD,
            })
        );
    };

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs((inputs) => ({ ...inputs, [name]: value }));

        setDefaultError();

        if (e.target.name === "password") {
            setConfirmPassword(false);
            if (e.target.value.length > 5) {
                setCheckMinPassword(true);
            } else {
                setSttMinPassword(false);
            }
            if (e.target.value === inputs["re_password"]) {
                setCheckConfirmPassword(true);
            } else {
                setCheckConfirmPassword(false);
            }
        }
        if (e.target.name === "re_password") {
            if (e.target.value === inputs["password"]) {
                setCheckConfirmPassword(true);
            } else {
                setCheckConfirmPassword(false);
            }
        }
    }

    function handleSubmit(e) {
        if (password.length > 5) {
            setSttMinPassword(false);
        } else {
            setSttMinPassword(true);
        }
        if (password !== re_password) {
            setConfirmPassword(true);
        } else {
            setConfirmPassword(false);
        }
        e.preventDefault();
        if (
            validateParam() &&
            checkConfirmPassWord === true &&
            checkMinPassWord === true
        ) {
            dispatch(teacherActions.resetPassword(inputs));
        }
    }

    function validateParam() {
        if (isNeedChangePassword) {
            return password.length > 5
            && password.trim() !== ''
            && re_password.length > 5
            && re_password.trim() !== ''
            && !sttMinPassWord
            && !sttMinConfirmPassWord
            ? true : false;
        }
        return password.length > 5
            && password.trim() !== ''
            && re_password.length > 5
            && re_password.trim() !== ''
            && old_password.length > 5
            & old_password.trim() !== ''
            && !sttMinPassWord
            && !sttMinOldPassWord
            && !sttMinConfirmPassWord
            ? true : false;
    }

    function changeStateOldPassWord() {
        if (sttOldPassword.type === "password") {
            setSttOldPassword({
                ...sttOldPassword,
                type: "text",
                status: "hide",
            });
        } else {
            setSttOldPassword({
                ...sttOldPassword,
                type: "password",
                status: "show",
            });
        }
    }

    function changeStatePassWord() {
        if (sttPassword.type === "password") {
            setSttPassword({
                ...sttPassword,
                type: "text",
                status: "hide",
            });
        } else {
            setSttPassword({
                ...sttPassword,
                type: "password",
                status: "show",
            });
        }
    }

    function changeStateRePassWord() {
        if (sttRePassword.type === "password") {
            setSttRePassword({
                ...sttRePassword,
                type: "text",
                status: "hide",
            });
        } else {
            setSttRePassword({
                ...sttRePassword,
                type: "password",
                status: "show",
            });
        }
    }

    function invaldOnBlur() {
        if ((old_password.length < 6 || old_password.trim() === '') && old_password.length > 0) {
            return setSttMinOldPassword(true);
        }

        if ((password.length < 6 || password.trim() === '') && password.length > 0) {
            return setSttMinPassword(true);
        }

        if ((re_password.length < 6 || re_password.trim() === '') && re_password.length > 0) {
            return setSttMinConfirmPassword(true);
        }
    }

    function logout() {
        apiRequestLogout(`${API.logout_account_service}`);
        apiRequestLogout(`${API.logout_account_exercise}`);
        window.location.href = "/login";
        dispatch({
          type: userConstants.RESET_ALL_STATE,
        });
        dispatch({
          type: userConstants.LOGOUT,
        });
        persistor.purge();
        // localStorage.clear();
        localStorage.removeItem("authentication");
        localStorage.removeItem("access_token");
        localStorage.removeItem("info_header_user");
        localStorage.removeItem("purposeLogin");
        localStorage.removeItem("date_selected");
        localStorage.removeItem("curriculum_info");
        localStorage.removeItem("curriculum_id_Selected");
    
        // localStorage.removeItem("device_id_commond");
        // history.push("/login");
      }

    // useEffect(() => {
    //     if (isNeedChangePassword) {
    //         dispatch({ type: userConstants.SHOW_SIDEBAR_ROOTLESSNESS });
    //     }
    // }, [])

    return (
        <div className="sunE-container flex bg-main-color">
            <div className="sunE-right-container flex-column">
                <Header title="Đổi mật khẩu" isBack clickBack={() => {
                    // if(isNeedChangePassword) {
                    //     logout();
                    //     return;
                    // }
                    history.goBack();
                }}/>
                <div className="sunE-container-box setting flex-1">
                    <form
                        className="sunE-container-box flex-1"
                        onSubmit={handleSubmit}
                        noValidate={true}
                    >
                        <div className="w380">
                            <div className="form-sunE-input rel">
                                <img
                                    src="/assets/images/icon/ico_password.png"
                                    className="ico_input width-22"
                                    alt="ico_password"
                                />
                                {/* {!isNeedChangePassword ?  */}
                                <input
                                    type={sttOldPassword.type}
                                    name="old_password"
                                    placeholder="Mật khẩu cũ"
                                    value={old_password}
                                    onChange={handleChange}
                                    onBlur={invaldOnBlur}
                                /> 
                                {/* : null} */}
                                {sttMinOldPassWord && (
                                    <AlertError
                                        message={"Mật khẩu phải có từ 6 ký tự trở lên và không được chứa toàn bộ dấu cách."}
                                        imgErorr
                                        errorHelpClassName="customErrorHelpClassName"
                                    />
                                )}
                                {!confirmPassWord &&
                                    !sttMinPassWord &&
                                    alert.message &&
                                    alert.screen === userConstants.SCREEN_RESET_PASSWORD && (
                                        <Alert
                                            alert={alert}
                                            onComplete={() => {
                                                // if (isNeedChangePassword) {
                                                //     dispatch({ type: userConstants.HIDE_SIDEBAR_ROOTLESSNESS });
                                                //     dispatch({
                                                //         type: userConstants.LOGIN,
                                                //         user: {
                                                //           ...authentication,
                                                //           need_change_password: 0
                                                //         },
                                                //       });
                                                //     history.push("/" + authentication.role)
                                                //     return;
                                                // }
                                                history.push("/" + authentication.role + "/more/setting")
                                            }}
                                        />
                                    )}
                                {sttOldPassword.type === "text" && (
                                    <img
                                        src="/assets/images/setting/ico_eye.png"
                                        alt="ico_eye"
                                        className="show-hide-password"
                                        onClick={changeStateOldPassWord}
                                    />
                                )}
                                {sttOldPassword.type === "password" && (
                                    <img
                                        src="/assets/images/setting/ico_visibility.png"
                                        alt="ico_visibility"
                                        className="show-hide-password"
                                        onClick={changeStateOldPassWord}
                                    />
                                )}
                            </div>
                            <div className="form-sunE-input rel">
                                <img
                                    src="/assets/images/icon/ico_password.png"
                                    className="ico_input width-22"
                                    alt="ico_password"
                                />
                                <input
                                    type={sttPassword.type}
                                    name="password"
                                    placeholder="Mật khẩu mới"
                                    value={password}
                                    onChange={handleChange}
                                    onBlur={invaldOnBlur}
                                />
                                {sttMinPassWord && (
                                    <AlertError
                                        message={"Mật khẩu phải có từ 6 ký tự trở lên và không được chứa toàn bộ dấu cách."}
                                        imgErorr
                                        errorHelpClassName="customErrorHelpClassName"
                                    />
                                )}
                                {sttPassword.type === "text" && (
                                    <img
                                        src="/assets/images/setting/ico_eye.png"
                                        alt="ico_eye"
                                        className="show-hide-password"
                                        onClick={changeStatePassWord}
                                    />
                                )}
                                {sttPassword.type === "password" && (
                                    <img
                                        src="/assets/images/setting/ico_visibility.png"
                                        alt="ico_visibility"
                                        className="show-hide-password"
                                        onClick={changeStatePassWord}
                                    />
                                )}
                            </div>
                            <div className="form-sunE-input rel">
                                <img
                                    src="/assets/images/icon/ico_password.png"
                                    className="ico_input width-22"
                                    alt="ico_password"
                                />
                                <input
                                    type={sttRePassword.type}
                                    name="re_password"
                                    placeholder="Nhập lại mật khẩu mới"
                                    value={re_password}
                                    onChange={handleChange}
                                    onBlur={invaldOnBlur}
                                />
                                {sttMinConfirmPassWord && (
                                    <AlertError
                                        message={"Mật khẩu phải có từ 6 ký tự trở lên và không được chứa toàn bộ dấu cách."}
                                        imgErorr
                                        errorHelpClassName="customErrorHelpClassName"
                                    />
                                )}
                                {sttRePassword.type === "text" && (
                                    <img
                                        src="/assets/images/setting/ico_eye.png"
                                        alt="ico_eye"
                                        className="show-hide-password"
                                        onClick={changeStateRePassWord}
                                    />
                                )}
                                {sttRePassword.type === "password" && (
                                    <img
                                        src="/assets/images/setting/ico_visibility.png"
                                        alt="ico_visibility"
                                        className="show-hide-password"
                                        onClick={changeStateRePassWord}
                                    />
                                )}
                            </div>
                            {confirmPassWord && !sttMinPassWord && (
                                <AlertError changePasswordError={true} imgErorr />
                            )}
                        </div>
                        <div className="form-sunE-button text-center">
                            <button
                                className={
                                    "btn-line-blue btn-p35" +
                                    (!validateParam() ? " btn-disable" : "")
                                }
                            >
                                Xác nhận
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export { ChangePasswordPage };
