import { convertSingleQuote } from "./utils";

export const formatLessonDataQuestion = (data) => {
  const skill = data?.lesson?.lesson_type || data?.data_question?.[0]?.list_option?.[0]?.type
  if (skill === "project") {
    return [{
      listQuestions: data?.data_question,
      skill
    }]
  }
  const listQuestions = data?.data_question?.map((item, index) => {
    if (skill === "vocabulary") {
      return item;
    }
    return {
      question_id: item?.question_id,
      list_option: item?.list_option,
      group_content: item?.list_option?.[0]?.group_content,
      group_content_vi: item?.list_option?.[0]?.group_content_vi,
      question_content: item?.list_option?.[0]?.question_content,
      skill,
      question_type: data?.data_question?.[0]?.list_option?.[0]?.question_type,
      hint: '',
      explain_parse: {
        content_question_text: ''
      },
      option_explain: '',
      questionNumber: index + 1
    }
  })
  const dataTarget = {
    group_id: data?.data_question?.[0]?.list_option?.[0]?.group_id,
    skill,
    question_type: data?.data_question?.[0]?.list_option?.[0]?.question_type,
    group_content: data?.data_question?.[0]?.list_option?.[0]?.group_content,
    content: '',
    group_script: '',
    group_title: '',
    listQuestions
  }
  return [dataTarget]
}

export const formatExamDataQuestions = (data) => {
  let listData = [];
  if (data?.data_group_question) {
    data?.data_group_question?.forEach((item) => {
      item.listQuestions = [];
      data?.data_question?.forEach((options) => {
        if (
          item.group_id === options?.list_option[0]?.group_id ||
          item.group_id === options?.list_option[0]?.category_id
        ) {
          let {
            group_content,
            group_content_vi,
            question_content,
            skill,
            question_type,
            group_script,
            jamming_answer_parse,
            content,
            match_option_text,
            group_file,
            hint,
            explain_parse,
            option_explain,
            group_title,
          } = options?.list_option[0];
          let question = {
            ...options,
            group_content,
            group_content_vi,
            question_content,
            skill,
            question_type,
            hint,
            explain_parse,
            option_explain,
          };
          item.listQuestions = [...item.listQuestions, question];
          item.group_content = group_content;
          item.content = content;
          item.group_script = group_script;
          item.group_title = group_title;
          if (item.skill + item.question_type === "reading3") {
            item.group_script = question_content;
            item.jamming_answer_parse = jamming_answer_parse;
          } else if (
            item.skill + item.question_type === "reading2" ||
            item.skill + item.question_type === "reading4"
          ) {
            item.match_option_text = match_option_text;
          } else if (item.skill + item.question_type === "writing2") {
            let textQuestion = question?.question_content
              .split("/")
              .sort(() => Math.random() - 0.5);
            question.question_content = textQuestion.join("/");
          }
          if (item.skill === "listening") {
            item.group_file = group_file;
          }
        }
      });
      if (
        item.skill + item.question_type === "reading3" ||
        item.skill + item.question_type === "reading2" ||
        item.skill + item.question_type === "reading4"
      ) {
        if (
          item.skill + item.question_type === "reading3" &&
          listData.filter(
            (data) =>
              data.skill + data.question_type === "reading3" &&
              (item.group_id === data.group_id ||
                item.group_id === data.category_id)
          ).length === 0
        ) {
          listData.push(item);
        } else if (
          item.skill + item.question_type === "reading4" &&
          listData.filter(
            (data) =>
              data.skill + data.question_type === "reading4" &&
              (item.group_id === data.group_id ||
                item.group_id === data.category_id)
          ).length === 0
        ) {
          listData.push(item);
        } else if (
          item.skill + item.question_type === "reading2" &&
          listData.filter(
            (data) =>
              data.skill + data.question_type === "reading2" &&
              (item.group_id === data.group_id ||
                item.group_id === data.category_id)
          ).length === 0
        ) {
          listData.push(item);
        }
      } else {
        listData.push(item);
      }
    });
  } else {
    data?.data_question?.map(question => {
      const option = question?.list_option?.[0];
      const dataFind = listData?.find(item => item?.group_id === option?.group_id)
      const dataQuestion = {
        explain_parse: option?.explain_parse,
        group_content: option?.group_content,
        group_content_vi: option?.group_content_vi,
        hint: option?.hint,
        list_option: question?.list_option,
        option_explain: option?.option_explain,
        question_content: option?.question_content,
        question_id: option?.question_id,
        question_type: option?.question_type,
        skill: option?.skill,
      }
      if (!!dataFind) {
        dataFind.listQuestions.push(dataQuestion);
      } else {
        const dataPush = {
          content: option?.content,
          group_content: option?.group_content,
          group_id: option?.group_id,
          group_script: option?.group_script,
          group_title: option?.group_title,
          question_type: option?.question_type,
          skill: option?.skill,
          listQuestions: [dataQuestion]
        };
        listData.push(dataPush);
      }
    })
  }
  let questionNumber = 1
  const result = listData?.map(item => {
    const listQuestions = item?.listQuestions?.map(question => {
      const newQuestion = {
        ...question,
        questionNumber,
        list_option: question?.list_option?.map(option => ({
          ...option,
          match_option_text: typeof option?.match_option_text === "string" ? convertSingleQuote(option?.match_option_text) : option?.match_option_text?.map(item => {
            if (typeof item === 'string') {
              return convertSingleQuote(item)
            }
            return item;
          })
        }))
      }
      questionNumber += 1
      return newQuestion
    })
    return {
      ...item,
      listQuestions,
    }
  })
  return result
};