import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { alertActions, teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { Alert } from "../../../_components/Alert";
import { teacherConstants, userConstants } from "./../../../_constants";
import { teacherService } from "./../../../_services";
import {
  PopUpYesNo,
  PopUpZoomImage,
  PopUpEditHomeWork,
  PopUpSetCriteria,
  PopUpHelp,
} from "./../../../_components/Popup";
import { useHistory } from "react-router-dom";
import { Audio } from "./../../../_components/Audio";
import $ from "jquery";
import Parser from "html-react-parser";
import HTMLReactParser from "html-react-parser";
import {
  forEach,
  findIndex,
  isEmpty,
  sortBy,
  isNaN,
  isEqual,
  cloneDeep,
} from "lodash";
import {
  validateDecimal2Number,
  checkPositiveFloat,
} from "../../../_base/Validate";

// import './EditHomeWork.css'
function EditHomeWork() {
  const history = useHistory();
  const authentication = useSelector((state) => state.authentication);
  const tabSelected = new URLSearchParams(window.location.search).get("type");
  const topic = new URLSearchParams(window.location.search).get("topic");
  const lesson_name = new URLSearchParams(window.location.search).get(
    "lesson_name"
  );
  const parentId = new URLSearchParams(window.location.search).get("parent_id");
  let params = new URLSearchParams(document.location.search);
  let isUnMark = params.get("isUnMark");
  let isGoBack = params.get("isGoBack");
  let heading = params.get("heading");

  let {
    id,
    exercise_id,
    exercise_type,
    user_exercise_id,
    library,
    type,
    unit_id,
    skill,
    lesson_id,
  } = useParams();

  const [criteriaNormal, setCriteriaNormal] = useState(null);
  const [exerciseId, setExcerciseId] = useState(exercise_id);
  const [studentInfo, setStudentInfo] = useState({});
  let dispatch = useDispatch();
  let homeworks = useSelector((state) => state.classes.homeworks);
  let criteria = useSelector((state) => state.classes.criteria);
  let alert = useSelector((state) => state.alert);
  const [isEdit, setIsEdit] = useState(false);
  const [saveResult, setSaveResult] = useState({});
  const [popupDetailResult, setPopupDetailResult] = useState(false);
  const [isAIEditing, setIsAIEditing] = useState(false);
  const [cachesList, setCachesList] = useState(
    homeworks.exercises.resource_data?.content
  );

  let criteriaScoreDefault =
    isEmpty(homeworks?.exercises?.old_result) ||
    isEmpty(homeworks?.exercises?.old_result?.json_criteria_score)
      ? criteriaNormal || criteria
      : JSON.parse(homeworks?.exercises?.old_result?.json_criteria_score);

  const [criteriaScore, setCriteriaScore] = useState(criteriaScoreDefault);

  let [dataEdit, setdataEdit] = useState({
    errList: [],
    fixList: [],
  });

  const [dataEditPrev, setDataEditPrev] = useState({
    errList: [],
    fixList: [],
  });

  let [inputs, setInputs] = useState({
    score: "",
    comment: "",
    user_exercise_id: user_exercise_id,
    library: library,
    exercise_type: exercise_type,
    json_criteria_score: [],
    json_writing_check: [],
  });

  let [zoom, setZoom] = useState(false);

  const [availableSubmit, setAvailableSubmit] = useState(
    authentication.role == userConstants.ROLE_TEACHER && !isUnMark
  );
  const [statusHomework, setStausHomeWork] = useState(0);
  let [contentEditAI, setContentEditAI] = useState();

  useEffect(() => {
    if (!isEmpty(criteriaScoreDefault) && exercise_type == "writing") {
      setCriteriaScore(criteriaScoreDefault);
    }
  }, [user_exercise_id, homeworks?.exercises?.old_result]);

  useEffect(() => {
    const newHomeWorks = cloneDeep(homeworks);
    if (newHomeWorks.detail?.data?.length > 0) {
      let student =
        authentication.role == userConstants.ROLE_TEACHER
          ? newHomeWorks?.detail?.data?.find(
              (user) => user.id == user_exercise_id
            ) || {}
          : newHomeWorks.exercises.exercise_data || {};
      dispatch({
        type: teacherConstants.GET_DETAIL_HOMEWORK,
        homeworks: newHomeWorks?.detail,
      });
      setStudentInfo(cloneDeep(student));
    }
  }, [homeworks.detail?.data?.length]);

  useEffect(() => {
    teacherService
      .getDetailHomeWorkExercise(user_exercise_id, library, exercise_type)
      .then((exercises) => {
        setIsAIEditing(
          type != "0" && !!exercises.old_result.ai_mark
            ? !!exercises.old_result.ai_mark
            : false
        );
        dispatch({
          type: teacherConstants.GET_DETAIL_HOMEWORK_EXERCISE,
          exercises: exercises,
        });
        setSaveResult(exercises);
        if (exercise_type == "writing") {
          setInputs({
            ...inputs,
            score: exercises.old_result.final_score,
            comment: exercises.old_result.comment,
          });
          setContentEdit(exercises.resource_data.content);
          setCachesList(exercises.resource_data.content);
          setFulltext(exercises.resource_data.content);
          // console.log(exercises.old_result.json_writing_check)
          exercises?.old_result?.json_writing_check &&
            convertDataHomeWordWriting(
              JSON.parse(exercises?.old_result?.json_writing_check)
            );
        } else {
          setInputs({
            ...inputs,
            score: parseInt(type) == 0 ? "" : exercises.exercise_data.score,
            comment:
              exercise_type == "speaking"
                ? exercises.old_result.comment ||
                  exercises.exercise_data.comment
                : exercises.exercise_data.comment ||
                  exercises.old_result.comment,
          });
        }

        // setAvailableSubmit(parseInt(exercises.exercise_data.status) == 1 ? false : true);
        setStausHomeWork(parseInt(exercises.exercise_data.status));
      });

    // get Criteria normal  apply all excercise
    teacherService.getCriteriaWorkExercise(null).then((criteria2) => {
      setCriteriaNormal(
        isEmpty(criteria2.data_criteria) ? null : criteria2.data_criteria
      );
      dispatch({
        type: teacherConstants.GET_LIST_CRITERIA,
        criteria: criteria2?.data_criteria,
      });
      if (isEmpty(criteriaScore)) {
        setCriteriaScore(criteria2?.data_criteria)
      }
    });

    $(document).on("click", function (e) {
      if ($(e.target).closest("#content-box-edit").length == 0) {
        $("span.popup-tag-edit").css("display", "none");
      }
    });
  }, []);

  // Func get character selected
  function getSelectionCharacterOffsetWithin(element) {
    var start = 0;
    var end = 0;
    var doc = element.ownerDocument || element.document;
    var win = doc.defaultView || doc.parentWindow;
    var sel;

    if (typeof win.getSelection != "undefined") {
      sel = win.getSelection();
      if (sel.rangeCount > 0) {
        var range = win.getSelection().getRangeAt(0);
        var preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.startContainer, range.startOffset);
        start = preCaretRange.toString().length;
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        end = preCaretRange.toString().length;
      }
    } else if (sel == doc.selection && sel.type != "Control") {
      var textRange = sel.createRange();
      var preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint("EndToStart", textRange);
      start = preCaretTextRange.text.length;
      preCaretTextRange.setEndPoint("EndToEnd", textRange);
      end = preCaretTextRange.text.length;
    }
    return { start: start, end: end };
  }

  // Func select text hightlight
  function selectText(event) {
    var selOffsets = getSelectionCharacterOffsetWithin(
      document.getElementById("content-box-edit")
    );
    let start = selOffsets.start;
    let end = selOffsets.end;
    // console.log(start, end)
    if (start != end) {
      let locationMousex = locationMouse.x;
      let locationMousey = locationMouse.y;
      if ((locationMousey = event.clientY)) {
        locationMousex += Math.abs((event.clientX - locationMousex) / 2);
      }

      let showPopUp = true;

      if (
        findIndex(dataEdit.errList, function (o) {
          return o.start <= start && o.end >= start;
        }) != -1 ||
        findIndex(dataEdit.errList, function (o) {
          return o.start <= end && o.end >= end;
        }) != -1 ||
        findIndex(dataEdit.fixList, function (o) {
          return o.start <= start && o.end >= start;
        }) != -1 ||
        findIndex(dataEdit.fixList, function (o) {
          return o.start <= end && o.end >= end;
        }) != -1
      ) {
        showPopUp = false;
      }
      // console.log('cachesList', cachesList, cachesList?.substring(start, end))

      // console.log("startt =====", selOffsets.start + "_" + selOffsets.end);

      if (showPopUp) {
        $("span.popup-tag-edit").css("display", "flex");
        $("span.popup-tag-edit").css("left", locationMousex);
        if (start >= 0 && end >= 0) {
          setTypeEdit("add");
          setcurrentDataErr({
            ...currentDataErr,
            start,
            end,
            text: window.getSelection().toString(),
          });
          setcurrentDataFix(defaultCurrentDataFix);
        }
      }
    }
  }

  let [locationMouse, setLocationMouse] = useState({
    x: 0,
    y: 0,
  });

  const defaultCurrentDataErr = {
    end: 0,
    isHide: false,
    start: 0,
    text: "",
    isHide: true,
    numberIncre: 0,
  };
  let [currentDataErr, setcurrentDataErr] = useState(defaultCurrentDataErr);

  const defaultCurrentDataFix = {
    type: 1,
    explain: "",
    fixed: "",
    ai: false,
    numberIncre: 0,
  };

  let [currentDataFix, setcurrentDataFix] = useState(defaultCurrentDataFix);

  function clickonMouseDown(event) {
    setLocationMouse({
      x: event.clientX,
      y: event.clientY,
    });
    if (
      event.target.className.includes("delete") ||
      event.target.className.includes("fix") ||
      event.target.className.includes("error") ||
      event.target.className.includes("err")
    ) {
      let { errList, fixList } = dataEdit;

      // $("span.popup-tag-edit").css("display", "block");
      // $("span.popup-tag-edit").css("left", event.clientX);
      let indexError = findIndex(errList, {
        id: event.target.getAttribute("id_error"),
      });

      let indexFix = findIndex(fixList, {
        id: event.target.getAttribute("id_fix"),
      });
      if (indexError != -1 || indexFix != -1) {
        setcurrentDataErr(errList[indexError]);
        setcurrentDataFix(fixList[indexFix]);
      }
      setTypeEdit("update");
      setDataUpdateWriting({
        indexError,
        indexFix,
      });
      if (authentication.role == userConstants.ROLE_TEACHER) {
        setOpenPopUpEditHomeWork(true);
      } else if (
        authentication.role == userConstants.ROLE_STUDENT &&
        !isEmpty(fixList[indexFix]?.explain)
      ) {
        setPopupDetailResult(true);
      }
    } else {
      $("span.popup-tag-edit").css("display", "none");
    }
  }

  function validateParam() {
    if (exercise_type === 'writing') {
      if ( Number(inputs.score) > 10 || Number(inputs.score) < 0) {
        return false;
      }
      if (criteriaScore?.every(item => Number(item?.score) >= 0)) {
        return true
      }
      return false
    }
    if (["speaking", "project"]?.includes(exercise_type)) {
      if(inputs.score !== '' && Number(inputs.score) <=10 && Number(inputs.score) >= 0) return true;
      return false
    }
    return true;
  }
  
  function handleChange(e) {
    const { name, value } = e.target;
    if (name == "comment") {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
      setIsEdit(true);
    } else {
      if (value <= 10 && value.toString().length <= 4) {
        setInputs((inputs) => ({ ...inputs, [name]: value }));
        setIsEdit(true);
      }
    }
  }

  // Handle Submit
  function handleSubmit() {
    if (validateParam()) {
      let message =
        statusHomework == 1 ? "Cập nhật bài thành công" : "Chấm bài thành công";
      dispatch(
        teacherActions.updatelHomeWorkExercise(
          {
            ...inputs,
            json_criteria_score: criteriaScore,
            comment: inputs.comment || "",
            json_writing_check: { ...dataEdit, cachesList: cachesList },
          },
          message
        )
      );
    }
  }

  const [askAI, setAskAI] = useState(false);
  const [askHELP, setAskHELP] = useState(false);
  const [OpenPopUpEditHomeWork, setOpenPopUpEditHomeWork] = useState(false);

  function callAI() {
    setIsEdit(true);
    setAskAI(false);
    dispatch(
      teacherActions.callAIHomeWork(
        homeworks.exercises.resource_data.content,
        studentInfo?.user_received_id,
        library,
        user_exercise_id,
        id
      )
    );
  }

  function showZoom() {
    setZoom(true);
  }

  // Convert Date Home Work Writing
  const convertDataHomeWordWriting = (data) => {
    let errList = data.errList;
    let fixList = data.fixList;
    let cachesList = data.cachesList || "";
    // console.log("cachesList1 ========", cachesList);

    if (data.type == "add") {
      errList.push(data.dataError);
      fixList.push(data.dataFix);
    } else if (data.type == "update") {
      if (dataUpdateWriting.indexError != -1) {
        errList[dataUpdateWriting.indexError] = data.dataError;
        fixList[dataUpdateWriting.indexError] = data.dataFix;
      }
    } else if (data.type == "delete") {
      errList.splice(dataUpdateWriting.indexError, 1);
      fixList.splice(dataUpdateWriting.indexError, 1);
    }
    errList = sortBy(errList, "start");
    fixList = sortBy(fixList, "start");
    let tempFixList = [];
    let tempErrorList = [];
    if (data.type == "add") {
      dataUpdateWriting.indexError = 0;
      forEach(errList, function (value, key) {
        if (data.dataError.start >= value.end) {
          dataUpdateWriting.indexError = key + 1;
          return true;
        }
      });
    }

    forEach(fixList, function (value, key) {
      if (
        ((data.type == "update" || data.type == "add") &&
          key > dataUpdateWriting.indexError) ||
        (data.type == "delete" && key >= dataUpdateWriting.indexError)
      ) {
        let idError = value.idErr.split("_");
        tempFixList.push({
          ...value,
          start: parseInt(value.start) + parseInt(data.addNumberToId),
          end: parseInt(value.end) + parseInt(data.addNumberToId),
          id:
            parseInt(value.start) +
            parseInt(data.addNumberToId) +
            "_" +
            parseInt(parseInt(value.end) + parseInt(data.addNumberToId)),
          idErr:
            parseInt(idError[0]) +
            parseInt(data.addNumberToId) +
            "_" +
            parseInt(parseInt(idError[1]) + parseInt(data.addNumberToId)),
        });
      } else {
        tempFixList.push(value);
      }
    });

    forEach(errList, function (value, key) {
      if (
        ((data.type == "update" || data.type == "add") &&
          key > dataUpdateWriting.indexError) ||
        (data.type == "delete" && key >= dataUpdateWriting.indexError)
      ) {
        tempErrorList.push({
          ...value,
          start: parseInt(value.start) + parseInt(data.addNumberToId),
          end: parseInt(value.end) + parseInt(data.addNumberToId),
          id:
            parseInt(value.start) +
            parseInt(data.addNumberToId) +
            "_" +
            parseInt(parseInt(value.end) + parseInt(data.addNumberToId)),
        });
      } else {
        tempErrorList.push(value);
      }
    });

    fixList = tempFixList;
    errList = tempErrorList;
    setdataEdit({
      errList,
      fixList,
    });
    setCachesList(cachesList);

    // console.log("cachesList2 ========", cachesList);

    let curentCursor = 0;
    if (!isEmpty(fixList)) {
      contentEdit = "";
      forEach(fixList, function (value, key) {
        let indexOfError = findIndex(errList, { id: value.idErr });
        let dataError = value.idErr.split("_");
        // console.log('dataError ====', dataError)
        let class_explain = value.explain ? " have-explain" : "";
        if (value.start == value.end) {
          contentEdit +=
            cachesList?.substring(curentCursor, dataError[0]) +
            '<span class="delete' +
            class_explain +
            '" id_error=' +
            errList[indexOfError].id +
            " id_fix= " +
            value.id +
            ">" +
            cachesList.substring(dataError[0], dataError[1]) +
            "</span>";
          curentCursor = dataError[1];
        } else {
          if (value.type == 1) {
            contentEdit +=
              cachesList?.substring(curentCursor, value.start) +
              '<span class="fix' +
              class_explain +
              '"  id_error=' +
              errList[indexOfError].id +
              " id_fix= " +
              value.id +
              ">" +
              value.fixed +
              "</span>";
            if (indexOfError != -1 && errList[indexOfError].isHide) {
              contentEdit +=
                '<span style="display: inline-block; line-height: 10px" class="error delete' +
                class_explain +
                '"  id_error=' +
                errList[indexOfError].id +
                " id_fix= " +
                value.id +
                ">" +
                cachesList.substring(dataError[0], dataError[1]) +
                "</span>";
            } else {
              contentEdit +=
                '<span class="error' +
                class_explain +
                '" id_error=' +
                errList[indexOfError].id +
                " id_fix= " +
                value.id +
                ">" +
                cachesList.substring(dataError[0], dataError[1]) +
                "</span>";
            }
            curentCursor = dataError[1];
          } else {
            contentEdit += cachesList?.substring(curentCursor, dataError[0]);
            if (indexOfError != -1 && errList[indexOfError].isHide) {
              contentEdit +=
                '<span style="display: inline-block; line-height: 10px" class="error delete' +
                class_explain +
                '" id_error=' +
                errList[indexOfError].id +
                " id_fix= " +
                value.id +
                ">" +
                cachesList.substring(dataError[0], dataError[1]) +
                "</span>";
            } else {
              contentEdit +=
                '<span class="error' +
                class_explain +
                '" id_error=' +
                errList[indexOfError].id +
                " id_fix= " +
                value.id +
                ">" +
                cachesList.substring(dataError[0], dataError[1]) +
                "</span>";
            }
            contentEdit +=
              '<span class="fix' +
              class_explain +
              '" id_error=' +
              errList[indexOfError].id +
              " id_fix= " +
              value.id +
              ">" +
              value.fixed +
              "</span>";
            curentCursor = value.end;
          }
        }

        if (isEmpty(fixList[key + 1])) {
          contentEdit += cachesList?.substring(
            curentCursor,
            cachesList?.length
          );
        }
      });
      setContentEdit(contentEdit);
    }

    // console.log("cachesList3 ========", cachesList);
  };

  // Func Save Data Writing
  function saveDataWriting(data) {
    setIsEdit(true);
    setOpenPopUpEditHomeWork(false);
    setCachesList(data.cachesList);
    let errList = dataEdit.errList;
    let fixList = dataEdit.fixList;
    let cachesList = data.cachesList;

    if (data.type == "add") {
      errList.push(data.dataError);
      fixList.push(data.dataFix);
    } else if (data.type == "update") {
      if (dataUpdateWriting.indexError != -1) {
        errList[dataUpdateWriting.indexError] = data.dataError;
        fixList[dataUpdateWriting.indexError] = data.dataFix;
      }
    } else if (data.type == "delete") {
      errList.splice(dataUpdateWriting.indexError, 1);
      fixList.splice(dataUpdateWriting.indexError, 1);
    }
    errList = sortBy(errList, "start");
    fixList = sortBy(fixList, "start");
    let tempFixList = [];
    let tempErrorList = [];
    if (data.type == "add") {
      dataUpdateWriting.indexError = 0;
      forEach(errList, function (value, key) {
        if (data.dataError.start >= value.end) {
          dataUpdateWriting.indexError = key + 1;
          return true;
        }
      });
    }

    forEach(fixList, function (value, key) {
      if (
        ((data.type == "update" || data.type == "add") &&
          key > dataUpdateWriting.indexError) ||
        (data.type == "delete" && key >= dataUpdateWriting.indexError)
      ) {
        let idError = value.idErr.split("_");
        tempFixList.push({
          ...value,
          start: parseInt(value.start) + parseInt(data.addNumberToId),
          end: parseInt(value.end) + parseInt(data.addNumberToId),
          id:
            parseInt(value.start) +
            parseInt(data.addNumberToId) +
            "_" +
            parseInt(parseInt(value.end) + parseInt(data.addNumberToId)),
          idErr:
            parseInt(idError[0]) +
            parseInt(data.addNumberToId) +
            "_" +
            parseInt(parseInt(idError[1]) + parseInt(data.addNumberToId)),
        });
      } else {
        tempFixList.push(value);
      }
    });

    forEach(errList, function (value, key) {
      if (
        ((data.type == "update" || data.type == "add") &&
          key > dataUpdateWriting.indexError) ||
        (data.type == "delete" && key >= dataUpdateWriting.indexError)
      ) {
        tempErrorList.push({
          ...value,
          start: parseInt(value.start) + parseInt(data.addNumberToId),
          end: parseInt(value.end) + parseInt(data.addNumberToId),
          id:
            parseInt(value.start) +
            parseInt(data.addNumberToId) +
            "_" +
            parseInt(parseInt(value.end) + parseInt(data.addNumberToId)),
        });
      } else {
        tempErrorList.push(value);
      }
    });

    fixList = tempFixList;
    errList = tempErrorList;
    setdataEdit({
      ...dataEdit,
      errList,
      fixList,
    });

    // console.log(contentEdit, errList, fixList)
    let curentCursor = 0;
    if (!isEmpty(fixList)) {
      contentEdit = "";
      forEach(fixList, function (value, key) {
        let indexOfError = findIndex(errList, { id: value.idErr });
        let dataError = value.idErr.split("_");
        let class_explain = value.explain ? " have-explain" : "";
        if (value.start == value.end) {
          contentEdit +=
            cachesList.substring(curentCursor, dataError[0]) +
            '<span class="delete' +
            class_explain +
            '" id_error=' +
            errList[indexOfError].id +
            " id_fix= " +
            value.id +
            ">" +
            cachesList.substring(dataError[0], dataError[1]) +
            "</span>";
          curentCursor = dataError[1];
        } else {
          if (parseInt(value.type) == 1) {
            contentEdit +=
              cachesList.substring(curentCursor, value.start) +
              '<span class="fix' +
              class_explain +
              '"  id_error=' +
              errList[indexOfError]?.id +
              " id_fix= " +
              value?.id +
              ">" +
              value.fixed +
              "</span>";
            // console.log(contentEdit)
            if (indexOfError != -1 && errList[indexOfError].isHide) {
              contentEdit +=
                '<span style="display: inline-block; line-height: 10px" class="error delete' +
                class_explain +
                '"  id_error=' +
                errList[indexOfError].id +
                " id_fix= " +
                value.id +
                ">" +
                cachesList.substring(dataError[0], dataError[1]) +
                "</span>";
            } else {
              contentEdit +=
                '<span class="error' +
                class_explain +
                '" id_error=' +
                errList[indexOfError]?.id +
                " id_fix= " +
                value.id +
                ">" +
                cachesList.substring(dataError[0], dataError[1]) +
                "</span>";
            }
            curentCursor = dataError[1];
          } else {
            contentEdit += cachesList.substring(curentCursor, dataError[0]);
            if (indexOfError != -1 && errList[indexOfError].isHide) {
              contentEdit +=
                '<span style="display: inline-block; line-height: 10px" class="error delete' +
                class_explain +
                '" id_error=' +
                errList[indexOfError].id +
                " id_fix= " +
                value.id +
                ">" +
                cachesList.substring(dataError[0], dataError[1]) +
                "</span>";
            } else {
              contentEdit +=
                '<span class="error' +
                class_explain +
                '" id_error=' +
                errList[indexOfError].id +
                " id_fix= " +
                value.id +
                ">" +
                cachesList.substring(dataError[0], dataError[1]) +
                "</span>";
            }
            contentEdit +=
              '<span class="fix' +
              class_explain +
              '" id_error=' +
              errList[indexOfError].id +
              " id_fix= " +
              value.id +
              ">" +
              value.fixed +
              "</span>";
            curentCursor = value.end;
          }
        }

        if (isEmpty(fixList[key + 1])) {
          contentEdit += cachesList.substring(curentCursor, cachesList.length);
        }
      });
    } else {
      contentEdit = cachesList;
    }
    // console.log("contentEdit =====", contentEdit);
    // console.log("homeworks?.exercises?.AI =====", homeworks?.exercises?.AI);
    if (!isEmpty(homeworks?.exercises?.AI?.result_content)) {
      setContentEditAI(contentEdit);
    } else {
      setContentEditAI(contentEdit);
      setContentEdit(contentEdit);
    }
  }

  const [fullText, setFulltext] = useState(
    homeworks.exercises.resource_data?.content
  );

  const [cachesListAI, setCachesListAI] = useState(
    homeworks.exercises.resource_data?.content
  );
  let [contentEdit, setContentEdit] = useState(
    homeworks.exercises.resource_data?.content
  );
  // console.log(contentEdit, contentEditA)
  useEffect(() => {
    if (!isEmpty(homeworks.exercises.AI)) {
      let resultAI = homeworks?.exercises?.AI?.result_content;
      if (resultAI) {
        // console.log("resultAI =====", resultAI);
        let contentStr = "";
        let text = "";
        let fixList = [];
        let errList = [];
        resultAI.forEach((item) => {
          if (item.status == "origin") {
            contentStr = contentStr + item.text;
            text = text + item.text;
          } else {
            item.text = " " + item.text;
            item.fix_text = item.fix_text + " ";

            contentStr =
              contentStr +
              `<span class="text-err-ai" id_fix=${
                (text + item.text).length +
                "_" +
                (text + item.fix_text + item.text).length
              } id_error=${text.length + "_" + (text + item.text).length}>${
                item.text
              }</span>` +
              `<span class="text-fix-ai" id_fix=${
                (text + item.text).length +
                "_" +
                (text + item.fix_text + item.text).length
              } id_error=${text.length + "_" + (text + item.text).length}>${
                item?.fix_text
              }</span>`;

            fixList.push({
              ai: true,
              end: (text + item.fix_text + item.text).length,
              explain: "",
              fixed: item.fix_text,
              id:
                (text + item.text).length +
                "_" +
                (text + item.fix_text + item.text).length,
              idErr: text.length + "_" + (text + item.text).length,
              numberIncre: 0,
              start: (text + item.text).length,
              isHide: true,
              type: 0,
            });

            errList.push({
              ai: true,
              end: (text + item.text).length,
              id: text.length + "_" + (text + item.text).length,
              isHide: true,
              numberIncre: 0,
              start: text.length,
              text: item.text.trim() + " ",
              type: 0,
            });

            text = text + item.text + item.fix_text;
          }
        });

        setdataEdit({
          errList,
          fixList,
        });

        setCachesListAI(text);
        setCachesList(text);
        setContentEditAI(contentStr);

        return () => {
          dispatch({
            type: teacherConstants.CALL_AI_HOMEWORK,
            data: {},
          });
        };
      } else {
        setdataEdit({
          errList: [],
          fixList: [],
        });
        setCachesList(homeworks.exercises.resource_data.content);
        setContentEdit(homeworks.exercises.resource_data.content);
      }
    }
  }, [homeworks.exercises.AI]);

  const [typeEdit, setTypeEdit] = useState("add");
  const [dataUpdateWriting, setDataUpdateWriting] = useState({
    indexError: "",
    indexFix: "",
  });

  const [openSetCriteria, SetOpenSetCriteria] = useState(false);

  function saveCriteria(data, exerciseId) {
    if(data?.some(item => !item?.text)) {
      dispatch(
        alertActions.error({
          message: 'Tên tiêu chí không được để trống',
          screen: 'errorTextEditHomeWork',
          isShowPopup: true,
          textComplete: 'Đã hiểu'
        })
      );
      return;
    }
    setIsEdit(true);
    setExcerciseId(exerciseId);
    SetOpenSetCriteria(false);
    if (criteriaNormal) {
      setCriteriaNormal(data);
    }
    let scores = [];
    data.forEach((item1) => {
      let item2 = criteriaScore.filter(
        (item2, i) => item1.text == item2.text
      )[0];
      if (item2?.text) {
        item1.score = item2.score;
        scores.push(item1);
      } else {
        item1.score = 0;
        scores.push(item1);
      }
    });
    setCriteriaScore(scores);
    dispatch(teacherActions.updateCriteria(data, exerciseId, exercise_id));
  }

  function editCriteria() {
    return (
      availableSubmit &&
      (exercise_type == "speaking" || exercise_type == "project" ? (
        ""
      ) : (
        <div className="form-sunE-button mr-10 setting-tc">
          <button
            className="btn-line-blue"
            onClick={() => SetOpenSetCriteria(true)}
          >
            Cài đặt tiêu chí chấm
          </button>
        </div>
      ))
    );
  }

  const handleChangeScore = (index, value) => {
    let scores = [];
    if (parseInt(value) < 0) {
      value = 0;
    }
    if (value <= 10 && value.toString().length <= 4) {
      criteriaScore.forEach((item, i) => {
        if (i == index) {
          item.score = value;
        }
        scores.push(item);
      });
      setIsEdit(true);
      getScoreByAll(scores);
      setCriteriaScore(scores);
    }
  };

  const getScoreByAll = (scores) => {
    let score = 0;
    scores.forEach((item) => {
      score = score + (item.score || 0) * item.proportion;
    });
    setInputs({ ...inputs, score: parseFloat(score / 100) });
  };

  const goBackPage = () => {
    if (authentication.role == userConstants.ROLE_TEACHER) {
      if(isGoBack) {
        history.goBack();
        return;
      }
      history.push(
        "/" +
          authentication.role +
          "/class/view/" +
          id +
          "/homework/" +
          exercise_id +
          "/" +
          library +
          "/" +
          type
      );
    } else {
      history.go(-1);
    }
  };

  let countWords = contentEdit ? contentEdit.split(" ")?.length : 0;

  // Handle Open Modal Ask AI
  const handleOpenModalAskAI = () => {
    if (dataEdit.errList.length > 0) {
      setAskAI(true);
    } else {
      setDataEditPrev(dataEdit);
      setAskAI(false);
      callAI();
      setIsAIEditing(true);
    }
  };

  // Replace New Line
  const replaceNewlines = (node, index) => {
    if (node.type == "text") {
      return node.data.split("\n").map((text, i) => (
        <React.Fragment key={i}>
          {i > 0 && <br />}
          {text}
        </React.Fragment>
      ));
    }
  };

  // Change Score Deci
  const handleChangeScoreDecimal = (e) => {
    const { name, value } = e.target;
    if (
      // checkPositiveFloat(value) &&
      value <= 10 &&
      value.toString().length <= 4
    ) {
      setIsEdit(true);
      setInputs((inputs) => ({
        ...inputs,
        [name]: validateDecimal2Number(value),
      }));
    }
  };
  
  return (
    <div className="sunE-right-container flex-column">
      <Header
        title={!!heading ? heading :
          authentication.role == userConstants.ROLE_TEACHER
            ? studentInfo.to_fullname
            : parentId ? 'Bài chấm của phụ huynh' : "Bài chấm của giáo viên"
        }
        component={editCriteria()}
        isBack
        clickBack={goBackPage}
      />
      {OpenPopUpEditHomeWork && (
        <PopUpEditHomeWork
          type={typeEdit}
          cachesList={cachesList}
          currentDataErr={currentDataErr}
          currentDataFix={currentDataFix}
          onClickYes={(data) => saveDataWriting(data)}
          onClickNo={() => setOpenPopUpEditHomeWork(false)}
          width={340}
        />
      )}
      {popupDetailResult && (
        <PopUpYesNo
          title={"Giải thích"}
          labelNo={"Đóng"}
          hideButtonYes={true}
          message={currentDataFix.explain}
          onClickNo={() => setPopupDetailResult(false)}
        />
      )}
      {alert.message &&
        alert.screen == teacherConstants.SCREEN_EDIT_HOME_WORK && (
          <Alert
            alert={alert}
            onComplete={() =>
              history.push(
                "/" +
                  authentication.role +
                  "/class/view/" +
                  id +
                  "/homework/" +
                  exercise_id +
                  "/" +
                  library +
                  "/" +
                  type
              )
            }
          />
        )}
        {alert.message &&
        alert.screen == 'errorTextEditHomeWork' && (
          <div style={{zIndex: 100000}}><Alert alert={alert} /></div>
        )}
      {openSetCriteria && (
        <PopUpSetCriteria
          exercise_selected={exerciseId}
          exercise_id={exercise_id}
          criteria={criteriaScore}
          onClickNo={() => SetOpenSetCriteria(false)}
          onClickYes={(data, exercise_id) => saveCriteria(data, exercise_id)}
          width={420}
        />
      )}

      {(exercise_type == "speaking" || exercise_type == "project") && (
        <div className="flex-1 chambai writing">
          <div className="topic box-shadow">
            <span className="font-weight-bold color-confirm-success">
              {studentInfo?.lesson_name ||
                lesson_name ||
                saveResult?.exercise_data?.lesson_name ||
                saveResult?.resource_data?.title}
            </span>
            <h2>
              <span className="bold">Topic:</span>{" "}
              {studentInfo?.topic ||
                topic ||
                saveResult?.resource_data?.topic ||
                saveResult?.exercise_data?.topic}
            </h2>
          </div>
          <div className="text-center box-video-project img-bg">
            {homeworks.exercises.resource_data.file_type == "img" && (
              <img
                src={
                  homeworks.exercises.base_url +
                    homeworks.exercises.resource_data.file ||
                  homeworks.exercises.resource_data.path
                }
                alt="img"
                className="btn-zoom-img"
                onClick={() => showZoom()}
              />
            )}
            {homeworks.exercises.resource_data.file_type == "video" && (
              <video controls>
                <source
                  src={
                    homeworks.exercises.base_url +
                      homeworks.exercises.resource_data.file ||
                    homeworks.exercises.resource_data.path
                  }
                  className="btn-zoom-video"
                />
              </video>
            )}
            {homeworks.exercises.resource_data.file_type == "audio" && (
              <Audio
                link={
                  homeworks.exercises.base_url +
                    homeworks.exercises.resource_data.file ||
                  homeworks.exercises.resource_data.path
                }
                type={"audio"}
              />
            )}
          </div>
          <div className="box-shadow box-project-score-gr bg-white">
            <div className="flex-m project-score-gr">
              <div className="project-score mr-10 text-center">
                <h2>Điểm</h2>
                <input
                  type="text"
                  step="0.1"
                  onWheel={(e) => e.target.blur()}
                  name="score"
                  onChange={handleChangeScoreDecimal}
                  max={10}
                  min={0}
                  value={
                    inputs.score &&
                    (Number.isInteger(Number.parseFloat(inputs.score))
                      ? inputs.score
                      : Number.parseFloat(inputs.score))
                  }
                  disabled={!availableSubmit}
                />
              </div>
              <div className="flex-1 ml-10">
                <h2 className="pl-20">Nhận xét</h2>
                <textarea
                  placeholder={authentication?.role === userConstants.ROLE_STUDENT ? '' : "Viết nhận xét"}
                  defaultValue={inputs.comment || ""}
                  name="comment"
                  onChange={handleChange}
                  disabled={!availableSubmit}
                />
              </div>
            </div>
          </div>
          {zoom && (
            <PopUpZoomImage
              src={
                homeworks.exercises.base_url +
                homeworks.exercises.resource_data.file
              }
              onClickNo={() => setZoom(false)}
            />
          )}
        </div>
      )}

      {exercise_type == "writing" && (
        <div className="flex-1 chambai writing">
          {askAI && (
            <PopUpYesNo
              message={[
                {
                  id: 1,
                  msg_1: "Bạn có muốn chấm lại bằng chức năng",
                  strongText: "Chấm AI",
                  msg_2: "không?",
                },
                {
                  id: 2,
                  msg_1: "Bài chấm sẽ khôi phục về ban đầu!",
                },
              ]}
              onClickYes={() => {
                setDataEditPrev(dataEdit);
                callAI();
                setIsAIEditing(true);
              }}
              onClickNo={() => {
                setAskAI(false);
                setIsAIEditing(false);
              }}
            />
          )}
          {askHELP && (
            <PopUpHelp onClickNo={() => setAskHELP(false)} width={320} />
          )}
          <div className="topic box-shadow">
            <span className="font-weight-bold color-confirm-success">
              {studentInfo?.lesson_name ||
                lesson_name ||
                saveResult?.exercise_data?.lesson_name ||
                saveResult?.resource_data?.title}
            </span>
            <h2>
              <span className="bold">Topic:</span>{" "}
              {studentInfo?.topic ||
                topic ||
                saveResult?.resource_data?.topic ||
                saveResult?.exercise_data?.topic}
            </h2>
          </div>
          <div className="flex-m box-criteria-content">
            <div className="flex-1 mr-10">
              <div
                className={`box-shadow bg-line-blue box-criteria scrollbar-custom-4 ${
                  criteriaScore.length <= 4
                    ? "box-criteria-full"
                    : "box-criteria-scroll"
                }`}
              >
                <div className="flex-m mb-10">
                  <div className="flex-3 bg-blue text-center flex-center box-title-bg mr-10">
                    <p>Tiêu chí</p>
                  </div>
                  <div className="flex-2 bg-blue text-center flex-center box-title-bg mr-10">
                    <p>Tỷ trọng</p>
                  </div>
                  <div className="flex-2 bg-blue text-center flex-center box-title-bg">
                    <p>Điểm</p>
                  </div>
                </div>
                <div className="flex-m mb-10">
                  <div className="flex-5 flex-column box-shadow bg-white  box-criteria-list">
                    {criteriaScore &&
                      criteriaScore.map((data, i) => {
                        return (
                          <div
                            className="flex-1 flex-m bd-bot box-criteria-item"
                            key={"__score_" + i}
                          >
                            <div className="flex-3 pl-15 center-h-flex">
                              <p className="max-w two-line">{data.text}</p>
                            </div>
                            <div className="flex-2 center-flex">
                              <p>{data.proportion}%</p>
                            </div>
                            <div className="flex-2 center-flex">
                              <input
                                type="number"
                                step="0.1"
                                min={0}
                                placeholder="..."
                                max={10}
                                onWheel={(e) => e.target.blur()}
                                disabled={!availableSubmit}
                                className="__input_score_exercise input-hide-arrow"
                                value={data.score || ""}
                                onChange={(e) =>
                                  handleChangeScore(i, e.target.value)
                                }
                              ></input>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="flex-m">
                  <div className="flex-5 bg-white mr-10 box-bd-20 box-criteria-score-title center-h-flex">
                    <p className="flex-align">Điểm</p>
                  </div>
                  <div className="flex-2 bg-white box-bd-20 center-flex">
                    <p>
                      {inputs.score
                        ? Number.isInteger(Number.parseFloat(inputs.score))
                          ? inputs.score
                          : isEqual(
                              parseFloat(
                                Number.parseFloat(inputs.score).toFixed(2)
                              ),
                              "NaN"
                            )
                          ? ""
                          : parseFloat(
                              Number.parseFloat(inputs.score).toFixed(2)
                            )
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 ml-10">
              <div className="bg-white box-criteria-comment box-shadow">
                <h2>Nhận xét</h2>
                <textarea
                  name="comment"
                  placeholder={authentication?.role == userConstants.ROLE_STUDENT ? '' : "Nhận xét của bạn"}
                  value={inputs.comment || ""}
                  onChange={handleChange}
                  disabled={!availableSubmit}
                />
              </div>
            </div>
          </div>
          <div className="post-content box-shadow">
            <div className="flex-m">
              <h2 className="flex-1 title-post-box">Bài viết</h2>
              {availableSubmit && (
                <div className="flex-m btn-help-mark-gr">
                  {!isAIEditing ? (
                    <div
                      className="btn-mark rel"
                      onClick={() => handleOpenModalAskAI()}
                    >
                      <img
                        src="/assets/images/teacher/chambai/ico_cham_bai.png"
                        alt="ico_cham_bai"
                      />
                      <p>Chấm AI</p>
                    </div>
                  ) : (
                    <div
                      className="btn-mark rel"
                      onClick={() => {
                        setContentEditAI("");
                        setIsAIEditing(false);
                        setdataEdit(dataEditPrev);

                        if (!!homeworks?.exercises?.old_result?.ai_mark) {
                          setCachesList(
                            homeworks.exercises.resource_data?.content
                          );
                          setContentEdit(
                            homeworks.exercises.resource_data?.content
                          );
                          setdataEdit({
                            errList: [],
                            fixList: [],
                          });
                          setDataEditPrev({
                            errList: [],
                            fixList: [],
                          });
                        } else {
                          convertDataHomeWordWriting(
                            JSON.parse(
                              homeworks.exercises?.old_result
                                ?.json_writing_check
                            )
                          );
                        }
                      }}
                    >
                      <img
                        src="/assets/images/teacher/chambai/ico_cham_bai.png"
                        alt="ico_cham_bai"
                      />
                      <p>Hủy chấm AI</p>
                    </div>
                  )}
                  <div
                    className="btn-help center-flex"
                    onClick={() => setAskHELP(true)}
                  >
                    <img
                      src="/assets/images/teacher/chambai/ico_help.png"
                      alt="ico_help"
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className="mb-10">Tổng số từ: {countWords}</div>
              <div
                id="content-box-edit"
                className="content-box-edit"
                onMouseUp={(e) =>
                  authentication.role == userConstants.ROLE_TEACHER &&
                  selectText(e)
                }
                onMouseDown={(e) => {
                  // authentication.role == userConstants.ROLE_TEACHER &&
                  clickonMouseDown(e);
                }}
                style={{ whiteSpace: "pre-line" }}
              >
                {contentEditAI
                  ? HTMLReactParser(contentEditAI)
                  : HTMLReactParser(contentEdit || "")}
              </div>

              <span
                className="popup-tag-edit"
                style={{
                  top: locationMouse.y - 70,
                }}
                onClick={() =>
                  authentication.role == userConstants.ROLE_TEACHER
                    ? setOpenPopUpEditHomeWork(true)
                    : setPopupDetailResult(true)
                }
              >
                Sửa
              </span>
            </div>
          </div>
        </div>
      )}
      {availableSubmit && (
        <div className="text-right">
          <button
            className={
              "btn-line-blue btn-p35" +
              ((!validateParam() || !isEdit) &&
              ["speaking", "project", "writing"]?.includes(exercise_type)
                ? " btn-disable"
                : "")
            }
            onClick={handleSubmit}
            disabled={
              (!validateParam() || !isEdit) &&
              ["speaking", "project", "writing"]?.includes(exercise_type)
            }
          >
            Gửi học sinh
          </button>
        </div>
      )}
    </div>
  );
}

export { EditHomeWork };
